@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~semantic-ui-css/semantic.min.css';

@import '../node_modules/@syncfusion/ej2-base/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/fabric.css';
@import "../node_modules/@syncfusion/ej2-lists/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import '../node_modules/@syncfusion/ej2-navigations/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/fabric.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/fabric.css";


@media print{
    @page {
        size: landscape;
    }
}

#root {
    width: 100vw;
    height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.navbar-nav > .user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

[class*=sidebar-dark-] {
    background-color: #0a2b50;
}

.bg-primary {
    background-color: #0a2b50 !important;
}

.loading-time {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
}

#reportViewer1 {
    width: 100%;
    height: 79vh;
}

.report-table {
    width: 100vw;
    height: 79vh;
}